$(document).ready(function () {


  // custom select
  const
    select2 = ".js-select",
    select2Options = {
      minimumResultsForSearch: Infinity
    };

  if ($.fn.select2) {
    $(select2).select2(select2Options);
  }


  // modal
  const
    modal = ".js-modal",
    modalWrap = ".js-modal-wrap",
    modalOpen = ".js-modal-link",
    modalClose = "js-modal-close";

  if ($(modalOpen).length) {

    // modal ready
    $(document.body).append('<div class="modal js-modal"><div class="modal__wrap js-modal-wrap"></div></div>');

    // modal open
    $(document).on("click", modalOpen, function (e) {
      $.ajax({
        type: "GET",
        url: $(this).attr("href"),
        success: function (data) {
          $("body").addClass("open-modal");
          $(modal).addClass("open").find(modalWrap).html(data);
          // custom select
          if ($.fn.select2) {
            $(select2).select2(select2Options);
          }
        },
        error: function () {
          alert("fail");
        }
      });
      e.preventDefault();
    });

    // modal close
    $(document).on("click keyup", function (e) {
      if ($(e.target).hasClass(modalClose) || e.keyCode === 27) {
        $(modal).removeClass("open").one("transitionend", function () {
          $("body").removeClass("open-modal");
          $(modalWrap).empty();
        });
        e.preventDefault();
      }
    });

  }


});
